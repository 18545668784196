import { EVIDENTIAL_DOCS_MATRIX_INTERMEDIARY } from "../../data/evidential_docs_matrix_intermediary.data";

export const RESOURCE_LINK_ITEMS = {
  Customers: [
    {
      title: "Foreign Nationals (PDF, 351KB)",
      isDownload: true,
      isBlank: true,
      href: "/pdfs/Foreign_Nationals.pdf",
    },
    {
      title: "Overseas Customers (PDF, 642KB)",
      isDownload: true,
      isBlank: true,
      href: "/pdfs/Overseas_Customers.pdf",
    },
    {
      title: "Vulnerable customers (PDF, 683KB)",
      isDownload: true,
      isBlank: true,
      href: "/pdfs/Vulnerable_customers.pdf",
    },
  ],
  Guides: [
    {
      title: "Additional borrowing (PDF, 1417KB)",
      isDownload: true,
      isBlank: true,
      href: "/pdfs/Additional_borrowing.pdf",
    },
    {
      title: "New build useful guide (PDF, 649KB)",
      isDownload: true,
      isBlank: true,
      href: "/pdfs/New_build_useful_guide.pdf",
    },
    {
      title: "Packaging and process (PDF, 624KB)",
      isDownload: true,
      isBlank: true,
      href: "/pdfs/Packaging_and_process.pdf",
    },
    {
      title: "Product switching guide (PDF, 792KB)",
      isDownload: true,
      isBlank: true,
      href: "/pdfs/Product_switching.pdf",
    },
    {
      title: "Reasons to use HSBC UK (Residential) (PDF, 808KB)",
      isDownload: true,
      isBlank: true,
      href: "/pdfs/Reasons_to_use_HSBC_UK(residential).pdf",
    },
    {
      title: "Remortgage (PDF, 342KB)",
      isDownload: true,
      isBlank: true,
      href: "/pdfs/Remortgage.pdf",
    },
  ],
  Risk: [
    {
      title: "Cybercrime made simple (PDF, 717KB)",
      isDownload: true,
      isBlank: true,
      href: "/pdfs/Cybercrime_made_simple.pdf",
    },
    {
      title: "Family employment (PDF, 221KB)",
      isDownload: true,
      isBlank: true,
      href: "/pdfs/Family_Employment.pdf",
    },
    {
      title: "Fraud bulletin (PDF, 327KB)",
      isDownload: true,
      isBlank: true,
      href: "/pdfs/Fraud_bulletin.pdf",
    },
    {
      title: "Risk useful links (PDF, 127KB)",
      isDownload: true,
      isBlank: true,
      href: "/pdfs/Risk_useful_links.pdf",
    },
    {
      title: "Second employment (PDF, 203KB)",
      isDownload: true,
      isBlank: true,
      href: "/pdfs/Second_Employment.pdf",
    },
    {
      title: "Unconfirmed employment (PDF, 228KB)",
      isDownload: true,
      isBlank: true,
      href: "/pdfs/Unconfirmed_Employment.pdf",
    },
  ],
  CritPol: [
    {
      title: "Interest Only (PDF, 482KB)",
      isDownload: true,
      isBlank: true,
      href: "/pdfs/Interest_Only.pdf",
    },
  ],
  Income: [
    {
      title: "Employed income (PDF, 657KB)",
      isDownload: true,
      isBlank: true,
      href: "/pdfs/Employed_Income.pdf",
    },
  ],
  SpecLanding: [
    {
      title: "Buy to Let (PDF, 474KB)",
      isDownload: true,
      isBlank: true,
      href: "/pdfs/Buy_to_Let.pdf",
    },
    {
      title: "Consent to Let (PDF, 532KB)",
      isDownload: true,
      isBlank: true,
      href: "/pdfs/Consent_to_Let.pdf",
    },
    {
      title: "International BTL (PDF, 517KB)",
      isDownload: true,
      isBlank: true,
      href: "/pdfs/International_BTL.pdf",
    },
    {
      title: "Utilising rental income (PDF, 429KB)",
      isDownload: true,
      isBlank: true,
      href: "/pdfs/Utilising_Rental_Income.pdf",
    },
  ],
  UsefulApp: [
    {
      title: "Application amendment form (PDF, 188KB)",
      isDownload: true,
      isBlank: true,
      href: "/pdfs/Application_Amendment_Form.pdf",
    },

    {
      title: "Application form (PDF, 62KB)",
      isDownload: true,
      isBlank: true,
      href: "/pdfs/application-form.pdf",
    },
    {
      title: "Changes to your registration with HSBC UK (PDF, 46KB)",
      isDownload: true,
      isBlank: true,
      href: "/pdfs/Changes_to_your_registration_with_HSBC_UK.pdf",
    },
    {
      title: "Common keying errors (PDF, 634KB)",
      isDownload: true,
      isBlank: true,
      href: "/pdfs/Keying_errors.pdf",
    },
    {
      title: "Government Backed Loan Scheme declaration (PDF, 46KB)",
      isDownload: true,
      isBlank: true,
      href: "/pdfs/Government_Backed_Loan_Scheme_declaration.pdf",
    },
    {
      title: "High Value Mortgage Service Application Form (PDF, 62KB)",
      isDownload: true,
      isBlank: true,
      href: "/pdfs/High_value_mortgage_service_application_form.pdf",
    },
  ],
  UsefulCritPol: [
    {
      title: "Country Specific Credit Bureau Matrix (PDF, 552KB)",
      isDownload: true,
      isBlank: true,
      href: "/pdfs/Country Specific Credit Bureau Matrix.pdf",
    },
    {
      title: "Foreign currency matrix (PDF, 555KB)",
      isDownload: true,
      isBlank: true,
      href: "/pdfs/Foreign_Currency_Matrix.pdf",
    },
    {
      title: "Interest Only Repayment Plans (PDF, 584KB)",
      isDownload: true,
      isBlank: true,
      href: "/pdfs/Interest-only_repayment_plans.pdf",
    },
    {
      title: "Solicitor Additional Fees (PDF, 981KB)",
      isDownload: true,
      isBlank: true,
      href: "/pdfs/LMS_Optima_Legal_Additional_Charges.pdf",
    },
  ],
  UsefulPack: [
    {
      title: " Buy to Let Evidential Documents Matrix (PDF, 152KB)",
      isDownload: true,
      isBlank: true,
      href: "/pdfs/Evidential_Docs_Matrix_Intermediary.pdf",
    },
    {
      title: `${EVIDENTIAL_DOCS_MATRIX_INTERMEDIARY.title} (${EVIDENTIAL_DOCS_MATRIX_INTERMEDIARY.file_type}, ${EVIDENTIAL_DOCS_MATRIX_INTERMEDIARY.file_size})`,
      isDownload: true,
      isBlank: true,
      href: EVIDENTIAL_DOCS_MATRIX_INTERMEDIARY.link_href,
    },
    {
      title: "Verifying a customer’s address (PDF, 1294KB)",
      isDownload: true,
      isBlank: true,
      href: "/pdfs/Verifying_a_customers_address_September_2023.pdf",
    },
    {
      title: "Verifying proof of identity (PDF, 1056KB)",
      isDownload: true,
      isBlank: true,
      href: "/pdfs/Verifying_a_customer's_ID_March_2022.pdf",
    },
  ],
};

export const RESOURCE_DOWNLOADABLE_ITEMS = [];
